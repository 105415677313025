<template>
    <div class="w-full" :class="[cursorDisabled, borderError]">
        <AutoComplete 
            v-model="valueAddress" 
            class="w-full"
            :class="(error && !address) && 'p-invalid'"
            :suggestions="addresses" 
            @complete="completeAddress($event)" 
            field="code"
            placeholder="Endereço" 
            @item-select="checkAddress" 
            :disabled="!state || unique === false" forceSelection 
        />
    </div>
    <p class="p-invalid" v-if="error && !address">{{error}}</p>
    <DialogSelectCEP 
        v-model:visible="selectCep.status" 
        v-model:data="selectCep.data" 
        @itemSelected="itemSelected" 
    />
</template>

<script>
import { watch, ref, reactive, onMounted, computed } from "vue";
import { SearchAddressRequest, CheckAddressRequest } from "../../modules/core/grpc/generated/address_pb";
import addressService from "../../modules/core/services/addressService";
import DialogSelectCEP from "./DialogSelectCEP"
export default {
    props: [
        "address", 
        "city", 
        "state", 
        "cep", 
        "neighborhood", 
        "number", 
        "complement",
        "type", 
        "unique",
        "error",
    ],
    emits: [
        "update:address",
        "update:cep",
        "update:neighborhood",
        "update:number",
        "update:complement",
        "update:type",
        "update:unique"
    ],
    components: {
        DialogSelectCEP
    },
    setup(props, { emit }) {
        const valueAddress = ref();
        const addresses = ref([]);
        const selectCep = reactive({
            status: false,
            data: []
        });
        const cursorDisabled = computed(() => {
            if(!props.state || props.unique === false){
                return 'cursor-not-allowed'
            }
            return ''
        })
        const borderError = computed(() => {
            if((props.error && !props.address) && 'border-error'){
                return 'border-error'
            }
            return ''
        })

        const searchAddress = async (data) => {
            try {
                const arr = []
                let req = new SearchAddressRequest();
                req.setState(data.state)
                req.setCity(data.city)
                req.setStreet(data.street)
                let res = await addressService.searchAddress(req);

                // console.log(res)

                if (res.success) {
                    const obj = JSON.parse(res.data)

                    await obj.forEach(item => {
                        arr.push({
                            code: item.address,
                        })
                    })
                }

                addresses.value = arr
            } catch (error) {
                console.log(error)
            }
        }

        const completeAddress = event => {
            const obj = {
                city: props.city,
                state: props.state,
                street: event.query,
            }
            searchAddress(obj)
        }


        const changeAddress = async (e) => {
            const code = e.value
            // console.log(code)
        }

        const checkAddress = async (e) => {
            try {
                const obj = {
                    city: props.city,
                    state: props.state,
                    street: e.value.code,
                }

                // console.log('checkAddress: ', obj)
                let req = new CheckAddressRequest();
                req.setState(obj.state)
                req.setCity(obj.city)
                req.setStreet(obj.street)
                let res = await addressService.checkAddress(req);

                if (res.success) {
                    const sizeCeps = res.cepsList.length
                    emit("update:address", obj.street)
                    emit("update:type", 'state')

                    if (sizeCeps > 0) {
                        // console.log(res.cepsList)
                        selectCep.status = true
                        selectCep.data = res.cepsList
                        ListAddress(obj);
                    } else {
                        if (sizeCeps === 0) {
                            ListAddress(obj);
                        }
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }

        const ListAddress = async (obj) => {
            try {
                let req = new SearchAddressRequest();
                req.setState(obj.state)
                req.setCity(obj.city)
                req.setStreet(obj.street)
                let res = await addressService.listAddress(req);

                if (res.success) {
                    const obj = JSON.parse(res.data)
                    
                    emit("update:cep", obj[0].postalCode)
                    emit("update:address", obj[0].street)
                    emit("update:neighborhood", obj[0].neighborhood)
                    emit("update:number", '')
                    emit("update:complement", '')
                    emit("update:unique", false)
                }
            } catch (error) {
                console.log(error)
            }

        }

        const itemSelected = (item) => {
            emit("update:cep", item)
        }

        watch(
            () => props.address,
            (newValue, oldValue) => {
                if (newValue !== valueAddress.value) {
                    valueAddress.value = { code: newValue }
                }
            }
        );

        watch(
            () => props.state,
            (newValue, oldValue) => {
                if (props.type === 'state' && newValue) {
                    valueAddress.value = "";
                }
            }
        );

        onMounted(() => {
            if (props.address) {
                valueAddress.value = { code: props.address }
            }
        })

        return {
            valueAddress,
            addresses,
            searchAddress,
            completeAddress,
            changeAddress,
            checkAddress,
            selectCep,
            itemSelected,
            cursorDisabled,
            borderError,
        }
    },
};
</script>


<style lang="scss" scoped>
.border-error{
    border: 1px solid red;
    border-radius: 8px;
}
// ::v-deep(.) {

// }
</style>