<template>
    <div class="w-full" :class="[cursorDisabled, borderError]">
        <AutoComplete 
            v-model="valueCity" 
            class="w-full"
            :class="(error && !city) && 'p-invalid'"
            :suggestions="cities" 
            @complete="completeCity($event)" 
            field="code"
            placeholder="Cidade" 
            @item-select="checkCity" 
            :disabled="!state || unique === false" 
            forceSelection 
        />
    </div>
    <p class="p-invalid" v-if="error && !city">{{error}}</p>
</template>

<script>
import { watch, onMounted, ref, computed } from "vue";
import { SearchCityRequest, CheckCityRequest, } from "../../modules/core/grpc/generated/address_pb";
import addressService from "../..//modules/core/services/addressService";

export default {
    props: ["city", "state", "type", "cep", "unique", "error"],
    emits: ["update:city", "update:cep"],
    setup(props, { emit }) {   
        const valueCity = ref("");
        const cities = ref([]);
        const cursorDisabled = computed(() => {
            if(!props.state || props.unique === false){
                return 'cursor-not-allowed'
            }
            return ''
        })
        const borderError = computed(() => {
            if((props.error && !props.city) && 'border-error'){
                return 'border-error'
            }
            return ''
        })

        const searchCity = async (data) => {
            try {
                const arr = []
                let req = new SearchCityRequest();
                req.setState(data.state)
                req.setCity(data.city)
                let res = await addressService.searchCity(req);
                if (res.success) {
                    const obj = JSON.parse(res.data)

                    await obj.forEach(item => {
                        arr.push({
                            code: item.city,
                        })
                    })
                }

                cities.value = arr
            } catch (error) {
                console.log(error)
            }
        }

        const completeCity = event => {
            const obj = {
                city: event.query,
                state: props.state,
            }
            searchCity(obj)
        }


        const changeCity = async (e) => {
            const code = e.value
        }

        const checkCity = async (e) => {
            try {
                const obj = {
                    city: e.value.code,
                    state: props.state,
                }
                let req = new CheckCityRequest();
                req.setState(obj.state)
                req.setCity(obj.city)
                let res = await addressService.checkCity(req);

                if (res.success) {
                    emit("update:city", obj.city)
                    
                    if(res.cityList.length > 0){
                        // soon implementation of modal to validate the zip code.
                        emit("update:cep", res.cityList[0].postalcode)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }

        watch(
            () => props.city,
            (newValue, oldValue) => {
                if(newValue !== valueCity.value){
                    valueCity.value = newValue
                }
            }
        );

        watch(
            () => props.state,
            (newValue, oldValue) => {
                if(props.type === 'state' && newValue){
                    valueCity.value = "";
                }
            }
        );

        onMounted(() => {
            if(props.city){
                valueCity.value = props.city
            }
        })

        return {
            valueCity,
            cities,
            searchCity,
            completeCity,
            changeCity,
            checkCity,
            cursorDisabled,
            borderError
        }
    },
};
</script>


<style lang="scss" scoped>
.border-error{
    border: 1px solid red;
    border-radius: 8px;
}
// ::v-deep(.) {

// }
</style>