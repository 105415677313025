import patientProfileService from "../../../core/services/patientProfileService";
import { 
    AddLocationRequest,
} from "../../../core/grpc/generated/patientProfile_pb";

const AddLocation = async (formData) => {
    try {
        const req = new AddLocationRequest();
        req.setPostalcode(formData.cep);
        req.setState(formData.state);
        req.setCity(formData.city);
        req.setAddress(formData.address);
        req.setNumber(formData.number);
        req.setNeighborhood(formData.neighborhood);
        req.setComplement(formData.complement);
        req.setLatitude(20);
        req.setLongitude(20);

        const res = await patientProfileService.addLocation(req);

        return res;        
    } catch (error) {
        console.log(error);
    }
}

export {
    AddLocation
}