<template>
    <div class="flex flex-column gap-8">
        <!-- <div class="flex flex-column gap-6 inputs">
            <p>type: {{fullAddress.edition}} | {{ type }}</p>
            <p>cep: {{ fullAddress.cep }} | {{ cep }}</p>
            <p>state: {{ fullAddress.state }} | {{ state }}</p>
            <p>city: {{ fullAddress.city }} | {{ city }}</p>
            <p>address: {{ fullAddress.address }} | {{ address }}</p>
            <p>number: {{ fullAddress.number }} | {{ number }}</p>
            <p>complement: {{ fullAddress.complement }} | {{ complement }}</p>
            <p>neighborhood: {{ fullAddress.neighborhood }} | {{ neighborhood }}</p>
        </div> -->
        
        <Cep 
            v-model:cep="cep" 
            v-model:state="state" 
            v-model:city="city" 
            v-model:address="address"
            v-model:neighborhood="neighborhood"
            v-model:type="type"
            v-model:unique="unique"
            :error="msgErros?.postalCode[0]"        
            :disabled="edition"
            v-if="loaded"
        />
        
        <State 
            v-model:cep="cep" 
            v-model:state="state" 
            v-model:type="type" 
            :error="msgErros?.state[0]" 
            @clear="clearForState" 
            :disabled="edition"
            v-if="loaded"
        />

        <City 
            v-model:city="city" 
            :state="state" 
            :type="type" 
            v-model:cep="cep" 
            :unique="unique" 
            :error="msgErros?.city[0]"
            v-if="loaded"
        />
        
        <Address 
            v-model:address="address" 
            :city="city" 
            :state="state" 
            v-model:cep="cep" 
            v-model:neighborhood="neighborhood"
            v-model:number="number"
            v-model:complement="complement"
            v-model:type="type" 
            v-model:unique="unique"
            :error="msgErros?.address[0]"
            v-if="loaded"
        />

        <InputText 
            type="text"
            :class="(msgErros?.number[0] && !number) && 'p-invalid'"
            v-model="number" 
            placeholder="Número" 
            :disabled="!state && !cep" 
            v-if="loaded"
        />
        <p class="p-invalid" v-if="msgErros?.number[0] && !number">{{msgErros?.number[0]}}</p>

        <InputText 
            type="text" 
            v-model="complement" 
            placeholder="Complemento" 
            :disabled="!state && !cep" v-if="loaded" 
        />

        <InputText 
            type="text" 
            :class="(msgErros?.neighborhood[0] && !neighborhood) && 'p-invalid'"
            v-model="neighborhood" 
            placeholder="Bairro" 
            :disabled="(!state && !cep) || unique === false" 
            v-if="loaded"
        />
        <p 
            class="p-invalid" 
            v-if="msgErros?.neighborhood[0] && !neighborhood"
        >
            {{msgErros?.neighborhood[0]}}
        </p>
    </div>
</template>

<script>
import { watch, onMounted, ref } from "vue";
import {
    SearchCityRequest,
    CheckCityRequest,
} from "../../modules/core/grpc/generated/address_pb";
import addressService from "../../modules/core/services/addressService";
import Cep from "./Cep";
import State from "./State";
import City from "./City";
import Address from "./Address";

export default {
    props: ["fullAddress", "msgErros", "edition"],
    emits: ["update:fullAddress"],
    components: {
        Cep,
        State,
        City,
        Address,
    },
    setup(props, { emit }) {
        const loaded = ref(false);
        const type = ref("");
        const unique = ref(null);
        const cep = ref("");
        const state = ref("");
        const city = ref("");
        const address = ref("");
        const number = ref("");
        const complement = ref("");
        const neighborhood = ref("");

        const clear = () => {
            console.log('clear')
            state.value = "";
            city.value = "";
            address.value = "";
            number.value = "";
            complement.value = "";
            neighborhood.value = "";
            unique.value = null;
        }

        const clearForState = () => {
            console.log('clearForState')
            cep.value = "";
            city.value = "";
            address.value = "";
            number.value = "";
            complement.value = "";
            neighborhood.value = "";
            unique.value = null;
        }

        const getFullAddress = () => {
            return {
                cep: cep.value,
                state: state.value,
                city: city.value,
                address: address.value,
                number: number.value,
                complement: complement.value,
                neighborhood: neighborhood.value,
            };
        }

        watch(
            () => cep.value,
            (newValue, oldValue) => {
                if (!newValue && type.value !== 'state') {
                    console.log('cep clear')
                    clear()
                }
            }
        );
        
        
        watch(
            () => [
                cep.value,
                state.value,
                city.value,
                address.value,
                number.value,
                complement.value,
                neighborhood.value,
            ],
            () => {
                emit("update:fullAddress", getFullAddress());
            }
        );

        onMounted(async () => {
            if(props.edition){
                cep.value = props.fullAddress.cep;
                state.value = props.fullAddress.state;
                city.value = props.fullAddress.city;
                address.value = props.fullAddress.address;
                number.value = props.fullAddress.number;
                complement.value = props.fullAddress.complement;
                neighborhood.value = props.fullAddress.neighborhood;
            }
            loaded.value = true;
        })
        
        return {
            cep,
            state,
            city,
            address,
            number,
            complement,
            neighborhood,
            clearForState,
            type,
            unique,
            loaded
        }
    },
};
</script>


<style lang="scss" scoped>
.color {
    display: flex;
    align-items: center;
    width: max-content;

    .box-drop {
        padding: 5px 2px;
        cursor: pointer;
    }
}

.box-color {
    width: 28px;
    height: 28px;
}

.pallete {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 142px;
    width: max-content;
    padding: 6px;
    gap: 6px;
    border-radius: 8px;
    box-shadow: 0px 8px 24px rgba(176, 190, 197, 0.25), 0px 3px 5px rgba(176, 190, 197, 0.3);

    .box-color {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #BDBDBD;
    }
}

.animation-fadein {
    -webkit-animation: fadein 0.5s ease-in alternate;
    -moz-animation: fadein 0.5s ease-in alternate;
    animation: fadein 0.5s ease-in alternate;
}

.animation-fadeout {
    -webkit-animation: fadeout 0.5s ease-in alternate;
    -moz-animation: fadeout 0.5s ease-in alternate;
    animation: fadeout 0.5s ease-in alternate;
}

@keyframes fadein {
    from {
        opacity: 0;
        height: 0%;
    }

    to {
        opacity: 1;
        height: 100%;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
        height: 100%;
    }

    to {
        opacity: 0;
        height: 0%;
    }
}

::v-deep(.p-dropdown) {
    display: flex;
    align-items: center;
    height: 45px;
    border-radius: 8px;
    border-color: #F2F2F2;
}

::v-deep(input) {
    margin: 0;
}
</style>