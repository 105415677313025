<template>
    <Dialog class="modal" :style="{
        width: '402px',
        borderRadius: '8px',
        zIndex: 2000,
    }" :baseZIndex="2" :autoZIndex="false" :visible="visible" @hide="close" @update:visible="onUpdate">
        <template #header>
            <h5 class="m-auto">Selecione um dos CEPs localizados</h5>
        </template>

        <div class="content">
            <ul>
                <li v-for="item in data" :key="item" @click="itemSelected(item)">
                    {{ item }}
                </li>
            </ul>
        </div>
    </Dialog>
</template>

<script>
import { ref, onMounted } from 'vue';
export default {
    props: ["visible", "data"],
    emits: ["update:visible", "itemSelected", "closeall"],
    setup(_, { emit }) {
        const close = () => emit("update:visible", false)
        const onUpdate = val => (!val) && emit("update:visible", val);

        const CloseAll = () => {
            close();
            emit("update:visible", false);
            // emit("closeall", true);
        }

        const itemSelected = item => {
            close();
            emit("itemSelected", item);
        }

        return {
            close,
            onUpdate,
            CloseAll,
            itemSelected
        };
    },
};
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    align-items: flex-start;
    margin-top: 1.5rem;
    margin-bottom: -10px;
    z-index: 99999999999 !important;

    p {
        max-width: 300px;
        text-align: center;
        margin: 0 auto;
        color: #828282;
    }

    .buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 8px;
        margin-top: 24px;

        .btn-cancel {
            background: white;
            border: 1px solid white;
            box-shadow: none;
            color: #FF4500;
        }
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;

        li {
            width: 100%;
            padding: 10px;
            cursor: pointer;
            color: #848483;

            &:hover {
                background: #fafafa;
            }
        }
    }
}

.mt-16 {
    margin-top: 16px !important;
}

::v-deep(button) {
    max-width: 104px;
    height: 40px;
}

::v-deep(.p-dialog) {
    z-index: 2303 !important;
}
</style>
