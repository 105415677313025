import { AddressProtoServicePromiseClient } from '../grpc/generated/address_grpc_web_pb';

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new AddressProtoServicePromiseClient(apiUrl);

async function getAddress(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.getAddress(request, metadata, {});

    return response.toObject();
}

async function listStates(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listStates(request, metadata, {});

    return response.toObject();
}

async function searchItems(request) {

    const response = await svc.tussSearchItems(request, {});

    return response.toObject();
}

async function searchCity(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.searchCity(request, metadata, {});

    return response.toObject();
}

async function checkCity(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.checkCity(request, metadata, {});

    return response.toObject();
}

async function searchAddress(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.searchAddress(request, metadata, {});

    return response.toObject();
}

async function checkAddress(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.checkAddress(request, metadata, {});

    return response.toObject();
}

async function listAddress(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listAddress(request, metadata, {});

    return response.toObject();
}

export default {
    getAddress,
    listStates,
    searchCity,
    searchAddress,
    listAddress,
    checkCity,
    checkAddress,
}