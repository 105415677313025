<template>
    <div class="form">
        <div class="header">
            <h1>Endereço Residencial</h1>
        </div>
        
        <Pagination :value="4" :size="5" />

        <div class="limit">
            <img class="center" src="../../../../assets/images/accounts/ready.png" alt="" />
            <p class="text-code mt-20">Viu só, mais fácil que tomar injeção.</p>
            <p class="text-code mt-12">E que tal receber tratamento Home Care? Voce também pode encontrar aqui. Além disso, precisamos do seuendereço para finalizar o cadastro.</p>
            <Address class="mt-24" v-model:fullAddress="address" :msgErros="msgErros" />

            <div class="flex w-full justify-content-center mt-2">
                <Button class="btn-submit" @click="submit" :disabled="loading">
                    <span class="p-button-label">Continuar</span>
                    <i class="pi pi-spin pi-spinner" v-if="loading" />
                </Button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import Pagination from "../../components/Pagination";
import { useRouter } from "vue-router";
import Address from "../../../../components/Address"
import { 
    AddLocation
} from "./actions";

let router = useRouter();
const address = ref({ cep: "", state: "", city: "", address: "", number: "", complement: "", neighborhood: "" })
const msgErros = ref({ name: [], type: [], address: [], city: [], neighborhood: [], number: [], postalCode: [], state: [] })
const loading = ref(false);

const submit = async () => {
    loading.value = true;    	
    const res = await AddLocation(address.value);
    console.log(res);
    if(!res.success){
        const data = JSON.parse(res.data)
        let newObj = { name: [], type: [], address: [], city: [], neighborhood: [], number: [], postalCode: [], state: [] }

        Object.keys(data).map((item) => {
            newObj = { ...newObj, [item]: data[item] }
        });
        msgErros.value = newObj;
    } else{
        router.push("/account/upload");
    }
    loading.value = false;
};
</script>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    width: 458px;
    background: white;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 8px;
    padding-bottom: 32px;

    h1{
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        text-align: center;
        margin: 0;
        margin-bottom: 30px;
    }

    .header{
        width: 100%;
        padding: 16px 0;
        border-bottom: 1px solid #F2F2F2;
        text-align: center;
        h1{
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            margin: 0;
        }
    }
    .limit{
        width: 100% !important;
        padding: 0 32px;
        .text-code {
            font-size: 14px;
            line-height: 22px;
            font-family: Roboto, sans-serif;
            font-weight: 400;
            color: #828282;
            text-align: center;
            margin: 0 auto;
        }
    }
    .center{
        display: block;
        margin: auto;
        margin-right: auto;
    }
    .btn-submit{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        min-width: 230px;
        height: 42px !important;
    }
}

@media (max-width: 580px){
    .form{
        width: 100%;
    }
}

:deep() {
    .p-button{
        margin-top: 10px;
        padding: 20px 80px;
        width: max-content;
    }
    .p-dropdown {
        height: 50px;
        padding-left: 10px;
        padding-right: 8px;
        font-family: Roboto, sans-serif;
        font-size: 14px !important;
        font-weight: 400 !important;
        letter-spacing: 1.5px !important;
        .pi{
            font-size: 12px;
        }
    }
    input {
        height: 50px;
        padding: 0 16px;
        border-radius: 8px;
        font-family: Roboto, sans-serif;
        font-size: 14px !important;
        font-weight: 400 !important;
        letter-spacing: 1px !important;
        &:disabled{
            background: white;
            /* opacity: 1; */
            cursor: not-allowed !important;
        }
        &::placeholder{
            color: #BDBDBD;       
        }
    }
}
</style>